// import axios from 'axios';

import { store } from "../redux/store";
import { Axios } from "./axios.interceptor";

export const logout = ({ }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/register`, {})
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const userLogin = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/login`, {
      email,
      password,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const register = ({ name, email, schoolRef, password }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/register`, {
      name,
      email,
      schoolRef,
      password,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const studRegister = ({ name, email, password, district }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/register/school`, {
      name,
      email,
      password,
      district,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UserSignupOtp = ({ email, otp }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify-user-signup-otp`, {
      // Axios.post(`/api/v1/verify-otp`, {
      email,
      otp,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const SchoolSignUpOtp = ({ email, otp }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify-school-signup-otp`, {
      email,
      otp,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const forgotPass = ({ email }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/forgot-password`, {
      email,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const forgotPassOtp = ({ email, otp }) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/verify-otp`, {
      email,
      otp,
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const microdentialsData = () => {
  return new Promise((resolve, reject) => {
    Axios.get('/api/v1/get-all-microcredential/21')
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response?.data || err.message);
      });
  });
};

export const userDelete = (id) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/other/delete-user?user_db_id=${id}`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UseGetUserDetail = async (id) => {
  return await Axios.get(`/api/v1/get-profile/${id}`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}

export const UseUpdateUserDetail = async (id, userid, data) => {
  const formdata = new FormData();
  if (data?.tagline) {
    formdata.append('tagline', data?.tagline);
  }
  if (data?.profilePhoto) {
    formdata.append('profilePhoto', data?.profilePhoto);
  }
  if (data?.about) {
    formdata.append('about', data?.about);
  }
  if (data?.interests.length > 0) {
    data.interests.map((value, idx) => formdata.append(`interests[${idx}]`, value))
  }
  if (data?.documents) {
    formdata.append('documents', data?.documents);
  }
  if (data?.showcase) {
    formdata.append('showcase', data?.showcase);
  }
  if (data?.endorsements) {
    formdata.append('endorsements', data?.endorsements);
  }
  if (data?.batch) {
    formdata.append('batch', data?.batch);
  }
  if (data?.industries) {
    data.industries.map((value) => formdata.append(`industries`, value));
  }
  if (data?.achievementImages) {
    data.achievementImages.map((value) => formdata.append(`achievementImages`, value));
  }
  if (data?.showcase) {
    data.showcase.map((value, idx) => formdata.append(`showcase[${idx}]`, value));
  }
  if (data?.deleteDocumentsName) {
    data.deleteDocumentsName.map((value, idx) => formdata.append(`deleteDocumentsName[${idx}]`, value));
  }
  if (data?.achievements) {
    data.deleteDocumentsName.map((value, idx) => formdata.append(`deleteDocumentsName[${idx}]`, value));
  }
  return await Axios.get(`/api/v1/update-profile/${id}/65bcb703b736c4db89264794`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}


export const UseGetMentors = async () => {
  return await Axios.get(`/api/v1/get-all-mentors`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}

export const UseSendInvitation = async () => {
  return await Axios.get(`/api/v1/get-all-mentors`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}

export const UseGetPost = async (page, limit) => {
  return await Axios.get(`/api/v1/get-all-posts/anytime?page=${page}&limit=${limit}`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}


export const UseGetMyPost = async (candidateId, page, limit) => {
  return await Axios.get(`/api/v1/my-post/anytime?candidate_db_id=${candidateId}&page=${page}&limit=${limit}`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}

export const createPost = (uId, data) => {
  const formdata = new FormData();
  formdata.append('userId', uId);
  formdata.append("content", data.text);
  if (data?.images) {
    data.images.map((value) => formdata.append('media', value));
  }
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/create-post/${uId}`, formdata)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const updatePost = (pId, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/update-post/${pId}`, data)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const UseGetMicroCreadiential = async (candidateId) => {
  return await Axios.get(`/api/v1/get-all-microcredential/${candidateId}`)
    .then((res) => { return res.data })
    .catch((error) => {
      return error
    })
}

