import * as React from "react";

function MentorFilter({ setIsOpen }) {
  return (
    <div className="flex flex-col items-start p-10 min-h-screen bg-white">
      <div className="flex gap-5 justify-between self-stretch w-full max-md:max-w-full">
        <div className="my-auto text-2xl font-bebas tracking-widest text-neutral-700">
          FILTER
        </div>
        <img
          onClick={() => setIsOpen(false)}
          loading="lazy"
          src="/images/dashboard/celebrate/cross.png"
          className=" w-[34px] bg-gray-50 rounded-full cursor-pointer"
        />
      </div>
      <div className="mt-5 text-2xl font-bebas tracking-[1px] text-green-600">
        SUPERPOWERS
      </div>
      <div className="mt-5">
        <div className="flex gap-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Influencer</div>
        </div>
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Engager</div>
        </div>
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Community Builder</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Relationship Builder</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Facilitator</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Adapter</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Stylish Thinker</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Reflective Thinker</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Strategist</div>
        </div>{" "}
        <div className="flex gap-3 mt-3">
          <input type="checkbox" className="w-4 h-4 my-auto" />
          <div>Initiator</div>
        </div>
      </div>
      <div className="flex flex-wrap gap-5 self-center mt-10 max-md:max-w-full">
        <button className="px-7 py-3 text-[15px] text-center whitespace-nowrap bg-white rounded-xl shadow-[0px_0px_19px_rgba(0,0,0,0.13)] text-neutral-400 max-md:px-5">
          Reset
        </button>
        <button
          style={{
            background: "linear-gradient(90deg, #1D68FF 0%, #9A52DE97 100%)",
          }}
          className="px-7 py-3 text-[15px]  tracking-wider text-white uppercase rounded-xl shadow-[0px_4px_62px_rgba(153,171,198,0.18)] w-fit max-md:px-5"
        >
          Apply Now
        </button>
      </div>
    </div>
  );
}
export default MentorFilter;
